body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}



.srv-validation-message {
  font-size: 13px !important;
  color: red;
}

.Toastify__toast {
  border-radius: 6px;

}
.Toastify__toast--error {
  background-color: rgb(231, 22, 22);
  color: #fff;
}
.Toastify__toast--warning {
background-color: #f1c410;
color: #121110;
}
.Toastify__close-button--warning {
  color: #121110 !important;
}
.Toastify__toast--success {
background-color: rgb(17, 192, 17);
color: '#fff';
}

.Toastify__toast--default {
  background: #3498db !important;
  color: #fff !important;
  opacity: 0.6;
}

.Toastify__close-button--default {
  color: #fff !important;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
.srv-validation-message {
  font-size: 13px !important;
  color: red;
}